import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApp } from '../contexts/appContextProvider';
import { UserSecurityIcon } from './icons/UserSecurityIcon';

const styles = {
    button: {
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
};

export type ChangePasswordButtonProps = {
    disabled?: boolean;
};

export const ChangePasswordButton: React.FC<ChangePasswordButtonProps> = (props) => {
    const app = useApp();
    const { t } = useTranslation();

    const disabled = props.disabled ?? false;

    return (
        <Button
            data-testid="change-password-button"
            variant="contained"
            color="primary"
            onClick={(): void => {
                app.setShowChangePasswordDialog(true);
            }}
            startIcon={<UserSecurityIcon />}
            sx={styles.button}
            disabled={disabled}
        >
            {t('CHANGE_PASSWORD.TEXT')}
        </Button>
    );
};
