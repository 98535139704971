import { AuthContextProvider, AuthUIActions, ChangePasswordDialog } from '@brightlayer-ui/react-auth-workflow';
import { AxiosError, HttpStatusCode } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { AlertModal } from '../components/AlertModal';
import { useApp } from '../contexts/appContextProvider';
import { DrawerContext } from '../contexts/drawerContextProvider';
import { logoutUser, UserSelectors } from '../features/userSlice';
import { UserService } from '../services/user/user.service';
import i18nAppInstance from '../translations/i18n';
import { ROUTE_CONFIG } from './AppRouter';

/**
 * This component wraps all of our application routes so that we can manage the drawer state
 * and open the alert modal or change password modal from any of our pages.
 */
export const RouteWrapper: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const app = useApp();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentUser = useAppSelector(UserSelectors.userDetails);
    const dispatch = useAppDispatch();

    const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
        if (!currentUser) {
            throw new Error(t('CHANGE_PASSWORD.MISSING_USER'));
        }

        try {
            await UserService.changePassword({ currentPassword: oldPassword, newPassword, id: currentUser?.id });
        } catch (e) {
            if (e instanceof AxiosError && e.response?.status === HttpStatusCode.Forbidden) {
                throw new Error(t('bluiAuth:CHANGE_PASSWORD.ERROR_MESSAGE'));
            }
            throw new Error(t('bluiAuth:CHANGE_PASSWORD.PROBLEM_OCCURRED'));
        }
    };

    // We only use the change password functionality from BLUI, everything else uses Okta.
    const actions: AuthUIActions = {
        changePassword,
        initiateSecurity: () => Promise.resolve(),
        logIn: () => Promise.resolve(),
        forgotPassword: () => Promise.resolve(),
        verifyResetCode: () => Promise.resolve(),
        setPassword: () => Promise.resolve(),
    };

    return (
        <DrawerContext.Provider
            value={{
                drawerOpen,
                setDrawerOpen,
            }}
        >
            <Outlet />
            <AlertModal />
            <AuthContextProvider
                actions={actions}
                language="en"
                navigate={(destination): void => {
                    if (destination === -1) {
                        navigate(-1);
                    } else {
                        navigate(destination);
                    }
                }}
                routeConfig={ROUTE_CONFIG}
                i18n={i18nAppInstance}
            >
                <ChangePasswordDialog
                    dialogDescription={t('CHANGE_PASSWORD.DESCRIPTION')}
                    open={app.showChangePasswordDialog}
                    onFinish={(): void => {
                        app.setShowChangePasswordDialog(false);
                        dispatch(logoutUser());
                    }}
                    onPrevious={(): void => {
                        app.setShowChangePasswordDialog(false);
                    }}
                    errorDisplayConfig={{
                        onClose: (): void => {
                            app.setShowChangePasswordDialog(false);
                        },
                    }}
                />
            </AuthContextProvider>
        </DrawerContext.Provider>
    );
};
