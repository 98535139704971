import * as Colors from '@brightlayer-ui/colors';
import { Box, Button, Divider, Link, Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../app/hooks';
import { CopyButton } from '../../components/CopyButton';
import { FilledTextField } from '../../components/FilledTextField';
import { Footer } from '../../components/Footer';
import { RegenerateButton } from '../../components/RegenerateButton';
import { Spinner } from '../../components/Spinner';
import { UserSelectors } from '../../features/userSlice';
import { Logger } from '../../logger';
import { OrganizationService } from '../../services/organization/organization.service';
import {
    ExternalApplication,
    GetUsersInOrganization,
    Organization,
    OrganizationSecret,
} from '../../services/organization/types';
import { UserRole } from '../../services/user/types';
import { OrganizationId } from '../../types/idFlavors';
import { formatDate } from '../../utils';
import { ManageInstallerAppRegistration } from './ManageInstallerAppRegistration';
import { UsersList } from './UsersList';

const styles = {
    container: {
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '25px',
        flex: '1 0 auto',
        paddingBottom: '20px',
        width: '40%',
        '@media (max-width: 900px)': {
            width: '100%',
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    sectionHeader: {
        paddingBottom: 2,
    },
    header: {
        marginBottom: '1rem',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginRight: '25px',
    },
    textBoxes: {
        display: 'flex',
        flexDirection: 'column',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    pointOfContactContainer: {
        marginBottom: '10px',
    },
    pointOfContactText: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: Colors.black[500],
    },
    contactInfoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    contactInfoText: {
        color: Colors.black[400],
    },
    emailText: {
        color: Colors.blue[400],
    },
    credentialsContainer: {
        marginTop: '16px',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Ensures the container takes the full height of the screen
    },
    buttonContainer: (theme: Theme): object => ({
        paddingLeft: theme.spacing(1),
    }),
    button: {
        marginTop: '16px',
        marginBottom: '32px',
        textTransform: 'uppercase',
    },
    installerRegistrationContainer: {
        marginTop: '32px',
    },
    usersListContainer: {
        marginBottom: '32px',
    },
};

type OrganizationDetailsProps = {
    setShowError: (showError: boolean) => void;
    orgId: OrganizationId;
};

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = (props) => {
    const { setShowError, orgId } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [usersInOrg, setUsersInOrg] = useState<GetUsersInOrganization[]>([]);
    const [externalApp, setExternalApp] = useState<ExternalApplication>();
    const [secrets, setSecrets] = useState<OrganizationSecret[]>();
    const [organization, setOrganization] = useState<Organization>();

    const currentUserId = useAppSelector((state) => UserSelectors.userDetails(state))?.id;
    const userRole = usersInOrg ? usersInOrg.find((user) => user.id === currentUserId)?.role : undefined;
    const isUserOwner = userRole && currentUserId && userRole === UserRole.OWNER;

    const isExpired = (secretExpiry: string): boolean => secretExpiry < DateTime.now().toISO();

    useEffect(() => {
        const fetchOrganizationDetailsData = async (): Promise<void> => {
            try {
                setIsLoading(true);
                const [fetchedOrg, fetchedUsers, fetchedExternalApp] = await Promise.all([
                    OrganizationService.getOrganization(orgId),
                    OrganizationService.getUsersInOrganization(orgId),
                    // This returns `undefined` if the request fails so that everything else on the page is still accessible and only the 'Register' button is disabled
                    OrganizationService.getExternalApplication().catch(() => undefined),
                ]);
                setOrganization(fetchedOrg);
                setUsersInOrg(fetchedUsers);
                setExternalApp(fetchedExternalApp);
                setSecrets(fetchedOrg.serviceAccount.clientSecrets);

                setShowError(false);
            } catch (error) {
                Logger.error(String(error));
                setShowError(true);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchOrganizationDetailsData();
    }, [orgId]);

    const updateSecret = (updatedSecret: OrganizationSecret, secretIndex: number): void => {
        if (!secrets) {
            return;
        }

        const updatedSecrets = secrets.map((currentSecret, currentSecretIndex) => {
            if (currentSecretIndex === secretIndex) {
                return updatedSecret;
            }
            return currentSecret;
        });

        setSecrets(updatedSecrets);
    };

    if (isLoading || !organization || !secrets) {
        return <Spinner testId={'org-details-loading-spinner'} />;
    }

    const { name, email, phoneNumber } = organization.pointOfContact;

    return (
        <Box sx={styles.main}>
            <Box sx={styles.container}>
                <Box sx={styles.header}>
                    <Typography data-testid="item-details-header-title" variant="h4">
                        {organization.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" sx={styles.sectionHeader}>
                        {t('ORGANIZATION_DETAILS_PAGE.DESCRIPTION_HEADER')}
                    </Typography>
                    <Typography>{organization.description}</Typography>
                </Box>
                {isUserOwner && (
                    <Box sx={styles.buttonContainer}>
                        <Button
                            sx={styles.button}
                            variant="outlined"
                            color="primary"
                            data-testid="edit-organization-button"
                            onClick={(): void => {
                                navigate(`/organizations/${organization.id}/edit`);
                            }}
                        >
                            {t('ORGANIZATION_DETAILS_PAGE.EDIT_BUTTON')}
                        </Button>
                    </Box>
                )}
                <Divider />
                <Box sx={styles.credentialsContainer}>
                    <Typography variant="h5" sx={styles.sectionHeader}>
                        {t('ORGANIZATION_DETAILS_PAGE.ORGANIZATION_CREDENTIALS')}
                    </Typography>
                    <Box sx={styles.textBoxes}>
                        <FilledTextField
                            label={t('ORGANIZATION_DETAILS_PAGE.CLIENT_ID')}
                            defaultValue={organization.serviceAccount.clientId}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <CopyButton content={organization.serviceAccount.clientId} />,
                            }}
                        />
                        <Box sx={styles.details}>
                            {secrets.map((secret, secretIndex) => (
                                <FilledTextField
                                    key={secret.name}
                                    label={`${secret.name} ${t('ORGANIZATION_DETAILS_PAGE.EXPIRY')}`}
                                    value={formatDate(DateTime.fromISO(secret.expiration))}
                                    error={isExpired(secret.expiration)}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: isUserOwner && (
                                            <RegenerateButton
                                                secretName={secret.name}
                                                organizationName={organization.name}
                                                organizationId={organization.id}
                                                setSecret={(updatedSecret): void => {
                                                    updateSecret(updatedSecret, secretIndex);
                                                }}
                                                isOrgRegisteredWithInstallerApp={
                                                    !!externalApp && externalApp.organizations.includes(organization.id)
                                                }
                                            />
                                        ),
                                    }}
                                    helperText={
                                        isExpired(secret.expiration)
                                            ? `${secret.name} ${t('ORGANIZATION_DETAILS_PAGE.IS_EXPIRED')}`
                                            : undefined
                                    }
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box style={styles.usersListContainer}>
                    <UsersList users={usersInOrg} organization={organization} isUserOwner={!!isUserOwner} />
                </Box>
                <Divider />
                <Box style={styles.installerRegistrationContainer}>
                    <ManageInstallerAppRegistration
                        organizationName={organization.name}
                        organizationId={organization.id}
                        isUserOwner={!!isUserOwner}
                        externalApp={externalApp}
                    />
                </Box>
            </Box>
            <Footer>
                <Box sx={styles.pointOfContactContainer}>
                    <Typography sx={styles.pointOfContactText}>
                        {t('ORGANIZATION_DETAILS_PAGE.POINT_OF_CONTACT')}
                    </Typography>
                </Box>
                <Box sx={styles.contactInfoContainer}>
                    <Typography sx={styles.contactInfoText}>{name}</Typography>
                    <Typography sx={styles.contactInfoText}>•</Typography>
                    <Link href={`mailto:${email}`} underline="none" sx={styles.emailText}>
                        {email}
                    </Link>
                    {phoneNumber && (
                        <>
                            <Typography sx={styles.contactInfoText}>•</Typography>
                            <Typography sx={styles.contactInfoText}>{phoneNumber}</Typography>
                        </>
                    )}
                </Box>
            </Footer>
        </Box>
    );
};
