import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { GetUserRolesResponse, UpdateUser, User, UserRole } from '../services/user/types';
import { UserService } from '../services/user/user.service';
import { OrganizationId, UserId } from '../types/idFlavors';

export enum FetchedState {
    INITIAL = 'initial',
    FETCHED = 'fetched',
    FAILED = 'failed',
}

type Credentials = {
    accessToken: string;
    userId: UserId;
};

type UserOrganizationRolesState = {
    organizationId: OrganizationId;
    organizationName: string;
    role: UserRole;
};

type AuthenticatedState = {
    isAuthenticated: true;
    userDetails: User;
    roles: {
        fetchedState: FetchedState;
        data: UserOrganizationRolesState[];
    };
};

type UnauthenticatedState = {
    isAuthenticated: false;
};

type CurrentUser = AuthenticatedState | UnauthenticatedState;

export type UserState = {
    currentUser: CurrentUser;
    credentials?: Credentials;
};

const initialState: UserState = {
    currentUser: { isAuthenticated: false },
};

export const getUserRoles = createAsyncThunk<GetUserRolesResponse[], undefined, { state: RootState }>(
    'users/roles',
    async (_: undefined, { getState }) => {
        const state = getState();
        const userId = state.user.currentUser.isAuthenticated ? state.user.currentUser.userDetails.id : '';

        return await UserService.getUserRoles(userId);
    }
);

export const getUserDetails = createAsyncThunk<User, undefined, { state: RootState }>(
    'users/details',
    async (_, { getState }) => {
        const state = getState();

        const token = state.user.credentials?.accessToken ?? '';

        return await UserService.getUser(token);
    }
);

export const updateUser = createAsyncThunk<User, Omit<UpdateUser, 'id'>, { state: RootState }>(
    'users/update',
    async (args, { getState }) => {
        const state = getState();

        const userId = state.user.currentUser.isAuthenticated ? state.user.currentUser.userDetails.id : '';

        return await UserService.updateUser({ ...args, id: userId });
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCredentialsAndUser: (state, action: PayloadAction<{ credentials: { accessToken: string }; user: User }>) => {
            state.currentUser = {
                isAuthenticated: true,
                userDetails: action.payload.user,
                roles: {
                    fetchedState: FetchedState.INITIAL,
                    data: [],
                },
            };
            state.credentials = {
                accessToken: action.payload.credentials.accessToken,
                userId: action.payload.user.id,
            };
        },
        setTokens: (state, action: PayloadAction<{ accessToken: string }>) => {
            if (state.credentials) {
                state.credentials.accessToken = action.payload.accessToken;
            }
        },
        logoutUser: (state) => {
            state.currentUser = { isAuthenticated: false };
            state.credentials = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserRoles.fulfilled, (state, action) => {
            if (state.currentUser.isAuthenticated) {
                state.currentUser.roles.data = action.payload;
                state.currentUser.roles.fetchedState = FetchedState.FETCHED;
            }
        });
        builder.addCase(getUserRoles.rejected, (state) => {
            if (state.currentUser.isAuthenticated) {
                state.currentUser.roles.fetchedState = FetchedState.FAILED;
            }
        });
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            if (state.currentUser.isAuthenticated) {
                state.currentUser.userDetails = action.payload;
            }
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            if (state.currentUser.isAuthenticated) {
                state.currentUser.userDetails = action.payload;
            }
        });
    },
});

export const { setCredentialsAndUser, setTokens, logoutUser } = userSlice.actions;

export const UserSelectors = {
    credentials: (state: RootState): Credentials | undefined =>
        state.user.currentUser.isAuthenticated ? state.user.credentials : undefined,
    userDetails: (state: RootState): User | undefined =>
        state.user.currentUser.isAuthenticated ? state.user.currentUser.userDetails : undefined,
    userOrganizationRoles: (state: RootState): UserOrganizationRolesState[] =>
        state.user.currentUser.isAuthenticated ? state.user.currentUser.roles.data : [],
    userOrganizationRolesFetchedState: (state: RootState): FetchedState =>
        state.user.currentUser.isAuthenticated ? state.user.currentUser.roles.fetchedState : FetchedState.INITIAL,
};

export default userSlice.reducer;
