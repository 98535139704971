import * as Colors from '@brightlayer-ui/colors';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerNavGroup,
    DrawerNavItem,
    DrawerNavItemProps,
} from '@brightlayer-ui/react-components';
import { AccountTree, OpenInNew } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Description from '@mui/icons-material/Description';
import Menu from '@mui/icons-material/Menu';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { EatonLogo } from '../components/EatonLogo';
import { ConfigClient } from '../config';
import { useApp } from '../contexts/appContextProvider';
import { useDrawer } from '../contexts/drawerContextProvider';
import { logoutUser, UserSelectors } from '../features/userSlice';
import { AuthorizedDrawerPages, DrawerPages } from './routes';

const styles = {
    eatonLogo: {
        width: 104,
        height: 28,
        maxWidth: '46%',
    },
    sideDrawerImageCopyrightFooter: {
        backgroundColor: Colors.white['50'],
        height: '110px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    copyrightText: {
        maxWidth: '46%',
        maxHeight: '60%',
        flexWrap: 'wrap',
        lineHeight: '16px',
    },
    drawerHeader: {
        backgroundImage:
            'linear-gradient(rgba(0, 123, 193, 0.5), rgba(0, 123, 193, 0.5)), url(/images/title-overlay.png)',
    },
    drawerFooter: {
        background: Colors.white['300'],
    },
};

export const NavigationDrawer: React.FC = () => {
    const app = useApp();
    const dispatch = useAppDispatch();
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const location = useLocation();

    const { drawerOpen, setDrawerOpen } = useDrawer();
    const user = useAppSelector(UserSelectors.userDetails);

    const logout = async (): Promise<void> => {
        await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
        dispatch(logoutUser());
    };

    const [selected, setSelected] = useState(location.pathname);

    const externalLinks: DrawerNavItemProps[] = [
        {
            title: t('DRAWER.AUTH_API_LINK'),
            itemID: 'auth-api-docs-link',
            rightComponent: <OpenInNew fontSize="small" />,
            onClick: () => window.open(ConfigClient.authApiDocumentationUrl(window)),
        },
        {
            title: t('DRAWER.API_LINK'),
            itemID: 'api-docs-link',
            rightComponent: <OpenInNew fontSize="small" />,
            onClick: () => window.open(ConfigClient.apiDocumentationUrl(window)),
        },
    ];

    const handleNavigate = useCallback(
        (id: string): void => {
            navigate(id);
            setSelected(id);
        },
        [navigate, setSelected]
    );

    return (
        <Drawer
            data-testid={'drawer-icon'}
            open={drawerOpen}
            ModalProps={{
                onBackdropClick: (): void => {
                    setDrawerOpen(false);
                },
            }}
            variant={isMobile ? 'temporary' : 'persistent'}
            activeItem={selected}
        >
            <DrawerHeader
                title={t('DRAWER.TITLE')}
                sx={styles.drawerHeader}
                icon={
                    <Box data-testid="drawer-menu">
                        <Menu />
                    </Box>
                }
                onIconClick={(): void => {
                    setDrawerOpen(!drawerOpen);
                }}
            />
            <DrawerBody>
                <DrawerNavGroup
                    items={DrawerPages.map((page) => {
                        const Icon = page.icon;
                        return {
                            title: page.title,
                            itemID: page.route,
                            icon: Icon && <Icon data-testid={page.iconTestId} />,
                            onClick: (): void => {
                                handleNavigate(page.route);
                                if (isMobile) setDrawerOpen(false);
                            },
                        };
                    })}
                    hidePadding
                />
                <DrawerNavItem
                    key={'advanced-topics-drawer-item'}
                    itemID={'drawer-advanced-topics'}
                    title={t('DRAWER.ADVANCED_TOPICS')}
                    icon={
                        <Box data-testid="advanced-topics-dropdown">
                            <AccountTree />
                        </Box>
                    }
                    items={[
                        {
                            title: t('DRAWER.UDP_KEYS_TITLE'),
                            itemID: 'udp-keys',
                            onClick: () => navigate('/advancedTopics/udpKeys'),
                        },
                        {
                            title: t('DRAWER.LOCAL_COMMUNICATIONS_TITLE'),
                            itemID: 'local-communications',
                            onClick: () => navigate('/advancedTopics/localCommunication'),
                        },
                    ]}
                    onClick={(): void => navigate('/advancedTopics')}
                    hidePadding
                />
                <Divider />
                <DrawerNavItem
                    key={'api-documentation-expandable-item'}
                    itemID={'api-documentation'}
                    title={t('DRAWER.API_DOCUMENTATION')}
                    icon={
                        <Box data-testid="api-documentation-dropdown">
                            <Description />
                        </Box>
                    }
                    items={externalLinks}
                    hidePadding
                />
                {user && (
                    <>
                        <Divider />
                        <DrawerNavGroup
                            items={AuthorizedDrawerPages.map((page) => {
                                const Icon = page.icon;
                                return {
                                    title: page.title,
                                    itemID: page.route,
                                    icon: <Icon data-testid={page.iconTestId} />,
                                    onClick: (): void => {
                                        handleNavigate(page.route);
                                        if (isMobile) setDrawerOpen(false);
                                    },
                                };
                            })}
                            hidePadding
                        />
                    </>
                )}
            </DrawerBody>
            <DrawerFooter hideContentOnCollapse={true}>
                <Box sx={styles.drawerFooter}>
                    {user && (
                        <DrawerNavItem
                            itemID={'drawer-user-menu'}
                            title={`${user.firstName} ${user.lastName}`}
                            icon={<AccountCircleIcon />}
                            items={[
                                {
                                    title: t('DRAWER.MY_ACCOUNT'),
                                    itemID: 'my-account-menu-item',
                                    onClick: () => navigate('/account'),
                                },
                                {
                                    title: t('DRAWER.CHANGE_PASSWORD'),
                                    itemID: 'change-password-menu-item',
                                    onClick: (): void => {
                                        app.setShowChangePasswordDialog(true);
                                        navigate('/account');
                                    },
                                },
                                {
                                    title: t('DRAWER.LOGOUT'),
                                    itemID: 'log-out-menu-item',
                                    onClick: () => void logout(),
                                },
                            ]}
                        />
                    )}
                    <Box sx={styles.sideDrawerImageCopyrightFooter}>
                        <EatonLogo sx={styles.eatonLogo} />
                        <Typography variant={'caption'} sx={styles.copyrightText}>
                            {t('DRAWER.COPYRIGHT', { currentYear: new Date().getFullYear() })} <br />
                            {t('DRAWER.COPYRIGHT_RIGHTS')}
                        </Typography>
                    </Box>
                </Box>
            </DrawerFooter>
        </Drawer>
    );
};
