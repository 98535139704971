import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const accountInfoSchemas = {
    firstName: z.string().trim().min(1).max(32),
    lastName: z.string().trim().min(1).max(32),
    companyName: z.string().trim().min(1).max(32).optional(),
    phoneNumber: z
        .string()
        .refine((value) => isPossiblePhoneNumber(value, 'US'))
        .optional(),
};
