import {
    ReactRouterAuthGuard,
    ReactRouterGuestGuard,
    RegistrationContextProvider,
    RouteConfig,
} from '@brightlayer-ui/react-auth-workflow';
import { Tokens } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ProjectRegistrationUIActions } from '../auth/RegistrationUIActions';
import { setCredentialsAndUser, setTokens, UserSelectors } from '../features/userSlice';
import { Login } from '../pages/Login';
import { RegistrationWorkflow } from '../pages/Registration/RegistrationWorkflow';
import { UserService } from '../services/user/user.service';
import i18nAppInstance from '../translations/i18n';
import { AuthorizedDrawerPages, AuthorizedPages, DrawerDocumentationPages, DrawerPages } from './routes';
import { RouteWrapper } from './RouteWrapper';

export const ROUTE_CONFIG: RouteConfig = {
    LOGIN: '/login',
    REGISTER_SELF: '/register/create-account',
};

export const AppRouter: React.FC = () => {
    const { authState, oktaAuth } = useOktaAuth();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const credentials = useAppSelector(UserSelectors.credentials);

    const isAuthenticated = useMemo(
        () => (authState?.isAuthenticated ?? false) && !!credentials,
        [authState?.isAuthenticated, credentials]
    );

    useEffect(() => {
        oktaAuth.authStateManager.subscribe((newAuthState) => {
            const accessToken = newAuthState.accessToken?.accessToken;
            if (accessToken) {
                dispatch(setTokens({ accessToken }));
            }
        });
        return (): void => oktaAuth.authStateManager.unsubscribe();
    }, [oktaAuth]);

    const onSuccessfulLogin = async (tokens: Tokens): Promise<void> => {
        const accessToken = tokens.accessToken?.accessToken ?? '';
        const user = await UserService.getUser(accessToken);
        dispatch(setCredentialsAndUser({ user, credentials: { accessToken } }));
    };

    return (
        <Routes>
            <Route element={<RouteWrapper />}>
                {DrawerPages.map((page) => {
                    const RouteElement = page.component;
                    return <Route key={`route_${page.route}`} path={page.route} element={<RouteElement />} />;
                })}
                {{ ...DrawerDocumentationPages }}
            </Route>
            <Route
                path={'/login'}
                element={
                    <ReactRouterGuestGuard isAuthenticated={isAuthenticated} fallBackUrl="/">
                        <Login onSuccessfulLogin={onSuccessfulLogin} />
                    </ReactRouterGuestGuard>
                }
            />
            <Route
                element={
                    <ReactRouterAuthGuard isAuthenticated={isAuthenticated} fallBackUrl={'/login'}>
                        <RouteWrapper />
                    </ReactRouterAuthGuard>
                }
            >
                {AuthorizedDrawerPages.map((page) => {
                    const RouteElement = page.component;
                    return <Route key={`route_${page.route}`} path={page.route} element={<RouteElement />} />;
                })}
                <>{AuthorizedPages}</>
            </Route>
            <Route path={'*'} element={<Navigate to={'/'} />} />

            <Route
                element={
                    <RegistrationContextProvider
                        language="en"
                        routeConfig={ROUTE_CONFIG}
                        i18n={i18nAppInstance}
                        navigate={(destination): void => {
                            if (destination === -1) {
                                navigate(-1);
                            } else {
                                navigate(destination);
                            }
                        }}
                        actions={ProjectRegistrationUIActions()}
                    >
                        <Outlet />
                    </RegistrationContextProvider>
                }
            >
                <Route path={'/register/create-account'} element={<RegistrationWorkflow />} />
            </Route>
        </Routes>
    );
};
