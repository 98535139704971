import {
    RegistrationWorkflow as BluiRegistration,
    CreatePasswordScreen,
    EulaScreen,
} from '@brightlayer-ui/react-auth-workflow';
import { useState } from 'react';
import { AccountDetailsPage } from './AccountDetails/AccountDetailsPage';
import { CreateAccountPage } from './CreateAccount/CreateAccountPage';
import { RegistrationSuccessPage } from './RegistrationSuccessPage/RegistrationSuccessPage';

export const RegistrationWorkflow = (): JSX.Element => {
    const [hasEatonAccount, setHasEatonAccount] = useState(false);

    const createAccountPage = (): JSX.Element => (
        <CreateAccountPage key={'createAccountScreen'} setHasEatonAccount={setHasEatonAccount} />
    );

    const eulaPage = (): JSX.Element => <EulaScreen key={'eulaPage'} html />;

    const accountDetailsPage = (): JSX.Element => <AccountDetailsPage key={'accountDetailsPage'} />;

    const createPasswordScreen = (): JSX.Element => <CreatePasswordScreen key={'createPasswordScreen'} />;

    const childrenScreens = hasEatonAccount
        ? [eulaPage(), createAccountPage(), accountDetailsPage()]
        : [eulaPage(), createAccountPage(), createPasswordScreen(), accountDetailsPage()];

    return (
        <BluiRegistration successScreen={<RegistrationSuccessPage hasEatonAccount={hasEatonAccount} />}>
            {childrenScreens}
        </BluiRegistration>
    );
};
