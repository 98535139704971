import * as Colors from '@brightlayer-ui/colors';
import { DrawerLayout, Spacer } from '@brightlayer-ui/react-components';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Button,
    IconButton,
    SxProps,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { useDrawer } from '../contexts/drawerContextProvider';
import { UserSelectors } from '../features/userSlice';
import { NavigationDrawer } from '../router/NavigationDrawer';
import { ProfileIcon } from './icons/ProfileIcon';

const styles = {
    drawerLayout: {
        height: '100%',
    },
    loginButton: {
        color: Colors.white['50'],
        backgroundColor: 'transparent',
        fontSize: '14px',
    },
    icon: {
        mr: 3,
    },
    title: {
        marginTop: '13px',
        marginBottom: '13px',
    },
    accountProfile: (theme: Theme): object => ({
        zIndex: 100,
        width: '76px',
        height: '76px',
        marginTop: '70px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }),
    accountInfoToolbar: (theme: Theme): object => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '108px',
        backgroundImage:
            'linear-gradient(90deg, #007BC1 5%, rgba(0, 123, 193, 0.2) 100%), url(/images/title-overlay.png)',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            height: '64px',
        },
    }),
    toolbar: (theme: Theme): object => ({
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '56px',
        alignItems: 'center',
    }),
    accountInfoContent: (theme: Theme): object => ({
        paddingTop: '50px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: '56px',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            height: '64px',
        },
    }),
};

type PageWrapperProps = {
    title: string;
    userProfile?: boolean;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
};

export const PageWrapper: React.FC<PageWrapperProps> = (props: PageWrapperProps) => {
    const { setDrawerOpen } = useDrawer();
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const credentials = useAppSelector(UserSelectors.credentials);

    return (
        <DrawerLayout drawer={<NavigationDrawer />} sx={styles.drawerLayout}>
            <Box>
                <AppBar position={'sticky'}>
                    <Toolbar sx={props.userProfile ? styles.accountInfoToolbar(theme) : styles.toolbar(theme)}>
                        {props.userProfile && (
                            <ProfileIcon sx={styles.accountProfile(theme)} data-testid={'profile-icon-page-wrapper'} />
                        )}
                        {md ? null : (
                            <IconButton
                                color={'inherit'}
                                onClick={(): void => {
                                    setDrawerOpen(true);
                                }}
                                edge={'start'}
                                sx={styles.icon}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography variant={'h6'} color={'inherit'} data-testid={'home-header'} sx={styles.title}>
                            {props.title}
                        </Typography>
                        <Spacer />
                        {!credentials && (
                            <Button sx={styles.loginButton} onClick={(): void => navigate('/login')}>
                                {t('COMMON.LOGIN')}
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <Box sx={props.userProfile ? styles.accountInfoContent : props.sx}>{props.children}</Box>
            </Box>
        </DrawerLayout>
    );
};
