import { UserId } from '../../types/idFlavors';
import { buildEnergyManagerClient, buildEnergyManagerClientWithoutAuth } from '../apiClient';
import {
    ChangePassword,
    CreateUser,
    GetUserRolesResponse,
    IUserService,
    UpdateUser,
    User,
    ValidateRegistrationRequest,
    ValidateRegistrationResponse,
} from './types';

const getUser = async (token: string): Promise<User> => {
    const apiClient = buildEnergyManagerClientWithoutAuth({ headers: { authorization: `Bearer ${token}` } });

    const response = await apiClient.get<User>(`/api/users/my/account`);
    return response.data;
};

const registerUser = async (args: CreateUser): Promise<User> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post<User>('/api/users', args);
    return response.data;
};

const getUserRoles = async (userId: UserId): Promise<GetUserRolesResponse[]> => {
    const apiClient = buildEnergyManagerClient();

    const response = await apiClient.get<GetUserRolesResponse[]>(`/api/users/${userId}/roles`);
    return response.data;
};

const validateRegistration = async (args: ValidateRegistrationRequest): Promise<ValidateRegistrationResponse> => {
    const apiClient = buildEnergyManagerClientWithoutAuth();

    const response = await apiClient.post<ValidateRegistrationResponse>('/api/users/validate-registration', args);

    return response.data;
};

const updateUser = async (args: UpdateUser): Promise<User> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        firstName: args.firstName,
        lastName: args.lastName,
        phoneNumber: args.phoneNumber,
        company: args.company,
    };

    const response = await apiClient.put<User>(`/api/users/${args.id}`, body);

    return response.data;
};

const changePassword = async (args: ChangePassword): Promise<void> => {
    const apiClient = buildEnergyManagerClient();

    const body = {
        currentPassword: args.currentPassword,
        newPassword: args.newPassword,
    };

    await apiClient.put(`/api/users/${args.id}/password`, body);
};

export const UserService: IUserService = {
    getUser,
    registerUser,
    getUserRoles,
    validateRegistration,
    updateUser,
    changePassword,
};
