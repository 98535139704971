import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/store';
import { AppContext } from './contexts/appContextProvider';
import { AlertProvider } from './lib/AlertContext';
import { getOktaConfig } from './oktaConfig';

import { AppRouter } from './router/AppRouter';
import i18nAppInstance from './translations/i18n';

const oktaAuth = new OktaAuth(getOktaConfig(window));

export const App = (): JSX.Element => {
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

    const navigate = useNavigate();

    const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string): void => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <AppContext.Provider
            value={{
                showChangePasswordDialog,
                setShowChangePasswordDialog,
            }}
        >
            <I18nextProvider i18n={i18nAppInstance}>
                <AlertProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                                <AppRouter />
                            </Security>
                        </PersistGate>
                    </Provider>
                </AlertProvider>
            </I18nextProvider>
        </AppContext.Provider>
    );
};
