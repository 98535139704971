import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '../app/store';
import { logoutUser, UserSelectors } from '../features/userSlice';

const getTimeout = (args?: AxiosRequestConfig): number =>
    args?.timeout && args.timeout > 0 ? 1000 * args.timeout : 1000 * 90;

export const buildEnergyManagerClientWithoutAuth = (args?: AxiosRequestConfig): AxiosInstance =>
    axios.create({
        ...args,
        headers: args?.headers,
        timeout: getTimeout(args),
    });

export const buildEnergyManagerClient = (args?: AxiosRequestConfig): AxiosInstance => {
    const credentials = UserSelectors.credentials(store.getState());
    const accessToken = credentials ? credentials.accessToken : undefined;

    const headers = {
        ...(args?.headers ?? {}),
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    };

    const axiosInstance = axios.create({
        ...args,
        headers,
        timeout: getTimeout(args),
    });

    axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
        if (error.response?.status === 401) {
            store.dispatch(logoutUser());
        }
        return Promise.reject(error);
    });

    return axiosInstance;
};
