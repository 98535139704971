import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollableNav } from '../../components/ScrollableNav';
import source from '../../markdown-content/localCommunications.english.md';

const LOCAL_COMMS_SECTIONS = (t: TFunction): Array<{ id: string; title: string }> => [
    {
        id: 'localCommunicationsOverview',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.OVERVIEW_TITLE'),
    },
    {
        id: 'securityOnTheLocalNetwork',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.SECURITY_TITLE'),
    },
    {
        id: 'keyTypes',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.KEY_TYPES_TITLE'),
    },
    {
        id: 'unicastCommunications',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.UNICAST_TITLE'),
    },
    {
        id: 'broadcastCommunications',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.BROADCAST_TITLE'),
    },
    {
        id: 'keyGenerationAndAssignment',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.GENERATION_AND_ASSIGNMENT_TITLE'),
    },
    {
        id: 'keyLifetimeAndRotation',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.LIFETIME_AND_ROTATION_TILE'),
    },
    {
        id: 'dataAvailability',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.DATA_AVAILABILITY_TITLE'),
    },
    {
        id: 'developingASmartBreakerCoordinator',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.DEVELOPING_COORDINATOR_TITLE'),
    },
    {
        id: 'securityConsiderations',
        title: t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.SECURITY_CONSIDERATIONS_TITLE'),
    },
];

export const LocalCommunicationPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper title={t('ADVANCED_TOPICS.LOCAL_COMMUNICATIONS.LOCAL_COMMUNICATIONS_TITLE')}>
            <ScrollableNav
                sectionList={LOCAL_COMMS_SECTIONS(t)}
                source={source}
                pageRoute="/advancedTopics/localCommunication"
            />
        </PageWrapper>
    );
};
